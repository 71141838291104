(function ($) {
    var elts = [];

    $.fn.euroviaShare = function (options) {
        // Establish our default settings
        var settings = $.extend({
        }, options);

        return this.each(function () {
            that = this;

            $(that).prepend("<div id='sharerButtons' style='display: none;'></div>");
            var sharers = $(that).find("#sharerButtons");
            $(that).prepend("<div id='sharer' class='symbol sharer'></div>");
            $(that).find("a").each(function (i, item) {
                elts.push(item);
                $(item).appendTo(sharers);

                $(item).click(function (e) {
                    e.preventDefault();
                    var href = $(item).attr('href');
                    window.open(href, "Eurovia share", "width=600, height=350, location=no, menubar=no, status=no, top=300, left=300");

                    return false;
                });
            });

            $(that).find("#sharer").click(function (e) {
                e.preventDefault();

                if (sharers.is(":visible")) {
                    sharers.fadeOut(333);
                } else {
                    sharers.fadeIn(333);
                }
            });
        });
    };
}(jQuery));